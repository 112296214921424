@import '../../common/variables.css';
@import '../../common/mixins/font.css';
@import '../../common/mixins/text.css';

.text {
  margin: 0;
  @mixin ml-font-family;
  @mixin ml-font-smoothing;
}

.extraLarge {
  @mixin text-extra-large;
}

.large {
  @mixin text-large;
}

.medium {
  @mixin text-medium;
}

.small {
  @mixin text-small;
}

.tiny {
  @mixin text-tiny;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize:first-letter {
  text-transform: uppercase;
}

.textNormal {
  @mixin text-normal;
}

.textBold {
  @mixin text-bold;
}

.textSemiBold {
  @mixin text-semi-bold;
}

.textColorInherit {
  color: inherit;
}

.textColorPrimary {
  @mixin text-color-primary;
}

.textColorSecondary {
  @mixin text-color-secondary;
}

.textColorNeutral {
  @mixin text-color-neutral;
}

.textColorAccepted {
  @mixin text-color-accepted;
}

.textColorCritical {
  @mixin text-color-critical;
}

.textColorWarning {
  @mixin text-color-warning;
}

.textColorPurple {
  @mixin text-color-purple;
}

.textColorOrange {
  @mixin text-color-orange;
}

.textColorTeal {
  @mixin text-color-teal;
}
.textColorWhite {
  @mixin text-color-white;
}
.textColorDisabled {
  @mixin text-color-disabled;
}

.textStrikethrough {
  @mixin text-strike-through;
}

.textEllipsis {
  @mixin text-ellipsis;
}

.textHyphens {
  @mixin text-hyphens;
}

.truncate {
  @mixin text-truncate var(--text-truncate-after-line);
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.textAlignCenter {
  text-align: center;
}

.textAlignJustify {
  text-align: justify;
}
